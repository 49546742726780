<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>

    <div class="container-fluid mt--7">
      <div class="row">

        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Настроки аккаунта</h3>
                </div>
                <div class="col-4 text-right">
                  <router-link to="/profile" class="btn btn-sm btn-primary">Профиль</router-link>
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">Доступ</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-4">
                      <base-input alternative=""
                                  label="Текущий пароль"
                                  placeholder="Текущий пароль"
                                  type="currentPassword"
                                  input-classes="form-control-alternative"
                                  v-model="model.passwordCurrent"
                      />
                    </div>
                    <div class="col-lg-4">
                      <base-input alternative=""
                                  label="Пароль"
                                  placeholder="Пароль"
                                  type="password"
                                  input-classes="form-control-alternative"
                                  v-model="model.password"
                      />
                    </div>
                    <div class="col-lg-4">
                      <base-input alternative=""
                                  label="Повторите пароль"
                                  placeholder="Повторите пароль"
                                  type="password"
                                  input-classes="form-control-alternative"
                                  v-model="model.passwordRepeat"
                      />
                    </div>
                  </div>
                </div>
                <hr class="my-4"/>
                <h6 class="heading-small text-muted mb-4">Устройство</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-md-12">
                      <base-input alternative=""
                                  label="MAC-адрес"
                                  placeholder="MAC-адрес  "
                                  input-classes="form-control-alternative"
                                  v-model="model.macAddress"
                      />
                    </div>
                  </div>
                </div>
                <hr class="my-4"/>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-md-12">
                      <base-button type="primary">Сохранить</base-button>
                    </div>
                  </div>
                </div>
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'user-settings',
  data() {
    return {
      model: {
        passwordCurrent: '',
        password: '',
        passwordRepeat: '',
        macAddress: '',
      }
    }
  },
  mounted() {
  },
  methods: {
    getAge(birthDateString) {
      var today = new Date();
      var birthDate = new Date(birthDateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    }
  }
};
</script>
<style></style>
