<template>
    <div>
        <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <departments-table title="Отделы"></departments-table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  import DepartmentsTable from './Tables/DepartmentsTable'
  export default {
    name: 'tables',
    components: {
      DepartmentsTable
    }
  };
</script>
<style></style>
