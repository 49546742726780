<template>
    <div>
        <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
            <!-- Card stats -->
            <div class="row">
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Отработанное время"
                                type="gradient-red"
                                :sub-title="timeOnlineHours"
                                icon="ni ni-active-40"
                                class="mb-4 mb-xl-0"
                    >

                        <template slot="footer">
                            <span class="text-success mr-2"><!--<i class="fa fa-arrow-up"></i> -->{{ timeOnlinePercent }}%</span>
                            <span class="text-nowrap">От общего времени</span>
                        </template>
                    </stats-card>
                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Сотрудники"
                                type="gradient-orange"
                                :sub-title="employeeOnlineNum"
                                icon="ni ni-chart-pie-35"
                                class="mb-4 mb-xl-0"
                    >

                        <template slot="footer">
                            <span class="text-success mr-2"><!--<i class="fa fa-arrow-up"></i> -->{{ employeeOnlinePercent }}%</span>
                            <span class="text-nowrap">От общего числа</span>
                        </template>
                    </stats-card>
                </div>
<!--                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Sales"
                                type="gradient-green"
                                sub-title="924"
                                icon="ni ni-money-coins"
                                class="mb-4 mb-xl-0"
                    >

                        <template slot="footer">
                            <span class="text-danger mr-2"><i class="fa fa-arrow-down"></i> 5.72%</span>
                            <span class="text-nowrap">Since last month</span>
                        </template>
                    </stats-card>

                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Performance"
                                type="gradient-info"
                                sub-title="49,65%"
                                icon="ni ni-chart-bar-32"
                                class="mb-4 mb-xl-0"
                    >

                        <template slot="footer">
                            <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 54.8%</span>
                            <span class="text-nowrap">Since last month</span>
                        </template>
                    </stats-card>
                </div>-->
            </div>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 mb-5 mb-xl-0">
                    <card type="default" header-classes="bg-transparent">
                        <div slot="header" class="row align-items-center">
                            <div class="col">
                                <h6 class="text-light text-uppercase ls-1 mb-1">Общее время</h6>
                                <h5 class="h3 text-white mb-0">Рабочее</h5>
                            </div>
                            <div class="col">
                                <ul class="nav nav-pills justify-content-end">
                                  <li v-for="router in bigLineChart.chartData.routers" :key="router.id" class="nav-item mr-2 mr-md-0">
                                    <a class="nav-link py-2 px-3"
                                       href="#"
                                       :class="{active: bigLineChart.activeIndex === router.id - 1}"
                                       @click.prevent="initBigChart(0)">
                                      <span class="d-none d-md-block">{{ router.name }}</span>
                                      <span class="d-md-none">M</span>
                                    </a>
                                  </li>
<!--                                    <li class="nav-item mr-2 mr-md-0">
                                        <a class="nav-link py-2 px-3"
                                           href="#"
                                           :class="{active: bigLineChart.activeIndex === 0}"
                                           @click.prevent="initBigChart(0)">
                                            <span class="d-none d-md-block">Месяц</span>
                                            <span class="d-md-none">M</span>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link py-2 px-3"
                                           href="#"
                                           :class="{active: bigLineChart.activeIndex === 1}"
                                           @click.prevent="initBigChart(1)">
                                            <span class="d-none d-md-block">Неделя</span>
                                            <span class="d-md-none">W</span>
                                        </a>
                                    </li>-->
                                </ul>
                            </div>
                        </div>
                        <line-chart
                                :height="350"
                                ref="bigChart"
                                :chart-data="bigLineChart.chartData"
                                :extra-options="bigLineChart.extraOptions"
                        >
                        </line-chart>

                    </card>
                </div>

<!--                <div class="col-xl-4">
                    <card header-classes="bg-transparent">
                        <div slot="header" class="row align-items-center">
                            <div class="col">
                                <h6 class="text-uppercase text-muted ls-1 mb-1">Performance</h6>
                                <h5 class="h3 mb-0">Total orders</h5>
                            </div>
                        </div>

                        <bar-chart
                                :height="350"
                                ref="barChart"
                                :chart-data="redBarChart.chartData"
                        >
                        </bar-chart>
                    </card>
                </div>-->
            </div>
            <!-- End charts-->

            <!--Tables-->
            <div class="row mt-5">
                <div class="col-xl-12 mb-5 mb-xl-0">
<!--                    <page-visits-table></page-visits-table>-->
                  <social-traffic-table></social-traffic-table>
                </div>
<!--                <div class="col-xl-4">

                </div>-->
            </div>
            <!--End tables-->
        </div>

    </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  //import BarChart from '@/components/Charts/BarChart';

  // Tables
  import SocialTrafficTable from './Dashboard/SocialTrafficTable';
  import axios from "axios";
  //import PageVisitsTable from './Dashboard/PageVisitsTable';

  export default {
    components: {
      LineChart,
      //BarChart,
      //PageVisitsTable,
      SocialTrafficTable,
    },
    data() {
      return {
        timeOnlineHours: '345 часов',
        timeOnlinePercent: 86,
        employeeOnlineNum: '3', // TODO: общее число сотрудников в офисе
        employeeOnlinePercent: 95, // TODO: общее число сотрудников в офисе
        bigLineChart: {
          allData: [],
          activeIndex: 0,
          chartData: {
            datasets: [],
            labels: [],
            routers: [],
          },
          extraOptions: chartConfigs.blueChartOptions,
        },
        redBarChart: {
          chartData: {
            labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [{
              label: 'Sales',
              data: [25, 20, 30, 22, 17, 29]
            }]
          }
        },
        periods: []
      };
    },
    methods: {
      initBigChart(index, routers) {
        let chartData = {
          datasets: [
            {
              label: 'Отработанное время от общего числа (%)',
              data: this.bigLineChart.allData[index].slice(-5)
            }
          ],
          labels: this.extractColumn(this.periods.slice(-5), 'name'),
          routers: routers,
        };
        this.bigLineChart.chartData = chartData;
        this.bigLineChart.activeIndex = index;
      },
      extractColumn(arr, column) {
        return arr.map(x => x[column])
      },
      calcPercent(totalSeconds, totalWorkTimeInSeconds, numEmployees) {
        return Math.round((totalSeconds / (totalWorkTimeInSeconds * numEmployees)) * 100)
      }
    },
    mounted() {
      let app = this;
      axios.get('/stat/periods-and-routers')
          .then(function (resp) {
            let routers = []
            resp.data.routers.forEach(function (router) {
              routers.push({
                id: router.routerId,
                name: router.routerName,
              })
              router.periods.forEach(function (period) {
                if (app.bigLineChart.allData[router.routerId] === undefined) {
                  app.bigLineChart.allData[router.routerId] = []
                }
                app.bigLineChart.allData[router.routerId].push(
                    app.calcPercent(period.total, period.totalWorkTime, router.numEmployees)
                )

                app.periods.push({
                  id: period.id,
                  name: period.name,
                })
              })
            })
            app.initBigChart(routers[0].id, routers);
          })
          .catch(function (resp) {
            console.log(resp);
            alert("Error");
          });
    }
  };
</script>
<style></style>
