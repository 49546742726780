<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{ userName }}
          </h3>
        </div>
        <div class="col text-right">
          <base-dropdown>
            <base-button slot="title" type="secondary" size="sm" class="dropdown-toggle">
              {{ periodName }}
            </base-button>
            <a class="dropdown-item" :href="`/employees-time/${userId}/period/${period.value}`" v-for="period in periods" v-bind:key="period.value">
              {{ period.text }}
            </a>
            <!--            <a class="dropdown-item" href="#">Action</a>
                        <a class="dropdown-item" href="#">Another action</a>
                        <a class="dropdown-item" href="#">Something else here</a>-->
          </base-dropdown>
          <base-button type="primary" size="sm">Добавить</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="times">
        <template slot="columns">
          <th>Дата</th>
          <th>Время прихода</th>
          <th>Время ухода</th>
          <th>Время в офисе</th>
          <th>Перерывы</th>
          <th>Переработка</th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <td>{{ row.date }} <i v-if="row.weekend" class="ni ni-calendar-grid-58 text-red"></i></td>
          <td>{{ row.beginTimeDesc }}</td>
          <td>{{ row.endTimeDesc }}</td>
          <td>
            {{ row.totalHours }} ч. {{ row.totalMinutes }} м.
            <div v-bind:key="bIndex" v-for="b, bIndex in row.routerTimes">
              {{ b.routerDescription }}: {{ b.routerTotal }}
            </div>
          </td>
          <td>
            <div v-bind:key="bIndex" v-for="b, bIndex in row.breaksDesc">
              {{ b.beginTimeDesc }} - {{ b.endTimeDesc }}
            </div>
          </td>
          <td>{{ row.overtimeHours }} ч. {{ row.overtimeMinutes }} м.</td>

          <td>
            <div class="d-flex align-items-center">
              <span class="completion mr-2">{{ row.completion }}%</span>
              <div>
                <base-progress :type="row.statusType"
                               :show-percentage="false"
                               class="pt-0"
                               :value="row.completion"/>
              </div>
            </div>
          </td>
        </template>
      </base-table>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: 'employees-time-table',
  data() {
    return {
      periods: [],
      periodId: 0,
      periodName: '',
      selectedPeriod: 0,
      userId: 1,
      times: [],
      DatePickerFormat: 'MMMM',
      date: null,
      userName: '',
      activeRow: {
        color: 'green'
      },
      inactiveRow: {
        color: 'red'
      },
    }
  },
  mounted() {
    var parameters = this.$route.params
    let app = this;
    this.userId = parameters.id
    this.periodId = parameters.period

    axios.get('/user/' + this.userId)
        .then(function (resp) {
          app.userName = resp.data.name
        })
        .catch(function (resp) {
          console.log(resp);
          alert("Could not load periods");
        });

    axios.get('/period')
        .then(function (resp) {
          var periods = resp.data.periods;
          periods.forEach(function (period) {
            app.periods.push({text: period.name, value: period.id})
            if (period.id == parameters.period) {
              app.periodName = period.name
            }
          });

          //var period = periods.slice(-1).pop().id;
          app.getData(parameters.period);
          app.selectedPeriod = parameters.period;
        })
        .catch(function (resp) {
          console.log(resp);
          alert("Could not load periods");
        });
  },
  methods: {
    getData: function (period) {
      let app = this;
      axios.get('/time/' + this.userId + '/period/' + period)
          .then(function (resp) {
            app.times = app.processRawTimeData(resp.data.time, app);
          })
          .catch(function (resp) {
            console.log(resp);
            alert("Could not load user times");
          });
    },
    processRawTimeData: function (rawTimeData, app) {
      var data = [];
      rawTimeData.forEach(function (timeRaw) {
        var tempTime = timeRaw;
        tempTime.beginTimeDesc = app.toDateTime(timeRaw.beginTime);
        tempTime.endTimeDesc = app.toDateTime(timeRaw.endTime);
        tempTime.totalHours = Math.floor(timeRaw.total / 3600);
        tempTime.totalMinutes = (timeRaw.total / 60) - (tempTime.totalHours * 60);
        tempTime.styleRow = 'base';

        var overtime = 28800 - timeRaw.total,
            overtimeHours = Math.floor(Math.abs(overtime) / 3600);
        tempTime.overtimeHours = overtime < 0 ? overtimeHours : (-1) * overtimeHours;
        tempTime.overtimeMinutes = (Math.abs(overtime) / 60) - (overtimeHours * 60);

        tempTime.styleRow = overtime < 0 ? app.activeRow : app.inactiveRow;

        tempTime.breaksDesc = [];
        if (tempTime.breaks) {
          (tempTime.breaks).forEach(function (breakItem) {
            var breakTemp = {
              beginTimeDesc: '',
              endTimeDesc: '',
            };
            breakTemp.beginTimeDesc = app.toDateTime(breakItem.beginTime);
            breakTemp.endTimeDesc = app.toDateTime(breakItem.endTime);
            (tempTime.breaksDesc).push(breakTemp)
          });
        }

        tempTime.routerTimes = [];
        if (tempTime.routers) {
          (tempTime.routers).forEach(function (routerTime) {
            (tempTime.routerTimes).push({
              routerName: routerTime.name,
              routerDescription: routerTime.description,
              routerTotal: app.toDateTime(routerTime.total),
            })
          });
        }


        const complete = Math.round(timeRaw.total / 28800 * 100);
        let status = 'pending';
        let statusType = 'warning';
        if (complete >= 30 && complete < 70) {
          status = 'delayed';
          statusType = 'danger';
        } else if (complete >= 70 && complete < 100) {
          status = 'on schedule';
          statusType = 'info';
        } else if (complete >= 100) {
          status = 'completed';
          statusType = 'success';
        }

        tempTime.status = status
        tempTime.statusType = statusType
        tempTime.completion = complete

        data.push(tempTime)
      });

      var lastTime = data[data.length - 1],
          unixSeconds = Math.floor(Date.now() / 1000);
      if (unixSeconds - lastTime.endTime <= 35) {
        lastTime.online = true;
      } else {
        lastTime.online = false;
      }

      return data;
    },
    toDateTime(seconds) {
      if (0 === seconds) {
        return '0 ч. 0 м.';
      }

      var date = new Date(seconds * 1000);

      return date.getHours() + ' ч. ' + date.getMinutes() + ' м.';
    }
  }
}
</script>
<style>
</style>
