<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <base-button type="primary" size="sm" @click="modals.modal0 = true">Добавить</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="tableData">
        <template slot="columns">
          <th>Название</th>
          <th>Описание</th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <a href="#" class="avatar rounded-circle mr-3">
                <i class="ni ni-building"></i>
<!--                <img alt="Image placeholder" :src="row.img">-->
              </a>
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.name }}</span>
              </div>
            </div>
          </th>
          <td>{{ row.description }}</td>

          <td class="text-right">
            <base-dropdown class="dropdown" position="right">
              <a slot="title" class="btn btn-sm btn-icon-only text-light" role="button" data-toggle="dropdown"
                 aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <a class="dropdown-item" href="#" @click="update(row.id)">Изменить</a>
                <a class="dropdown-item" href="#">Удалить</a>
              </template>
            </base-dropdown>
          </td>

        </template>
      </base-table>
    </div>
    <template>
      <div>
        <modal :show.sync="modals.modal0">
          <template slot="header">
            <h3 class="modal-title" id="periodFormModalLabel">Новый отдел</h3>
          </template>
          <div>
            <div class="row">
              <div class="col-md-12">
                <base-input ref="name" v-model="department.name" placeholder="Название"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input ref="name" v-model="department.description" placeholder="Описание"></base-input>
              </div>
            </div>
          </div>
          <template slot="footer">
            <base-button type="secondary" @click="modals.modal0 = false">Закрыть</base-button>
            <base-button type="primary" @click="this.department.id === 0 ? create : store">Сохранить</base-button>
          </template>
        </modal>

      </div>
    </template>
  </div>
</template>
<script>

import BTooltipDirective from 'bootstrap-vue/esm/directives/tooltip'
import axios from "axios";

export default {
  name: 'departments-table',
  directives: {
    'b-tooltip': BTooltipDirective
  },
  props: {
    type: {
      type: String
    },
    title: String,
  },
  data() {
    return {
      tableData: [],
      modals: {
        modal0: false
      },
      department: {
        id: 0,
        name: '',
        description: '',
      },
    }
  },
  mounted() {
    let app = this;

    axios.get('/department')
        .then(function (resp) {
          resp.data.forEach(function (item) {
            app.tableData.push({
              id: item.id,
              name: item.name,
              description: item.description,
            })
          })
        })
        .catch(function (resp) {
          console.log(resp);
          alert("Could not load departments");
        });
  },
  methods: {
    getDepartment(id) {
      let app = this
      axios.get('/department/' + id)
          .then(function (resp) {
            app.department.id = resp.data.id
            app.department.name = resp.data.name
            app.department.description = resp.data.description
          })
          .catch(function (resp) {
            console.log(resp)
            alert("Could not load department");
          });
    },
    create() {
      let app = this
      axios.post('/department', {
        Id: this.department.id,
        Name: this.department.name,
        Description: this.department.description,
      })
          .then(function () {
            app.modals.modal0 = false
            app.$notify({
              type: 'success',
              title: 'Отдел успешно добавлен'
            })
          })
          .catch(function (resp) {
            console.log(resp);
            app.modals.modal0 = false
          });
    },
    async update(id) {
      let app = this
      app.modals.modal0 = true
      await this.getDepartment(id)
    },
    store() {
      let app = this

      axios.patch('/department/' + app.department.id, {
        Id: app.department.id,
        Name: app.department.name,
        Description: app.department.description,
      })
          .then(function () {
            app.modals.modal0 = false
            app.$notify({
              type: 'success',
              title: 'Отдел успешно изменен'
            })
          })
          .catch(function (resp) {
            console.log(resp)
            app.modals.modal0 = false
          });
    }
  }
}
</script>
<style>
</style>
