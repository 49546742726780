<template>
    <div>
        <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <employees-time-table title="Сотрудники"></employees-time-table>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
  import EmployeesTimeTable from './Tables/EmployeesTimeTable'
  export default {
    name: 'tables',
    components: {
      EmployeesTimeTable
    }
  };
</script>
<style></style>
