<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <base-button type="primary" size="sm" @click="modals.modal0 = true">Добавить</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="tableData">
        <template slot="columns">
          <th>ФИО</th>
          <th></th>
          <th>MAC-address</th>
          <th>Время</th>
          <th>Общее время</th>
          <th>Статус</th>
          <th>План</th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <a href="#" class="avatar rounded-circle mr-3">
                <img alt="Image placeholder" :src="row.img">
              </a>
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.name }}</span>
              </div>
            </div>
          </th>
          <td>
            <i :class="`${row.routerIcon} text-${row.statusType}`" aria-hidden="true"></i>
          </td>
          <td>{{ row.macAddress }}</td>
          <td class="budget">
            {{ row.timeStart }} - {{ row.timeEnd }}
          </td>
          <td>
            {{ row.totalHours }} ч. {{ row.totalMinutes }} м.
          </td>
          <td>
            <badge class="badge-dot mr-4" :type="row.statusType">
              <i :class="`bg-${row.statusType}`"></i>
              <span class="status">{{ row.status }}</span>
            </badge>
          </td>

          <td>
            <div class="d-flex align-items-center">
              <span class="completion mr-2">{{ row.completion }}%</span>
              <div>
                <base-progress :type="row.completionColor"
                               :show-percentage="false"
                               class="pt-0"
                               :value="row.completion"/>
              </div>
            </div>
          </td>

          <td class="text-right">
            <base-dropdown class="dropdown" position="right">
              <a slot="title" class="btn btn-sm btn-icon-only text-light" role="button" data-toggle="dropdown"
                 aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <a class="dropdown-item" :href="`/employees-time/${row.id}/period/21`">Время</a>
                <a class="dropdown-item" href="#" @click="update(row.id)">Изменить</a>
                <a class="dropdown-item" href="#">Уволить</a>
              </template>
            </base-dropdown>
          </td>

        </template>

      </base-table>
    </div>
    <template>
      <div>
        <modal :show.sync="modals.modal0">
          <template slot="header">
            <h3 class="modal-title" id="periodFormModalLabel">Новый сотрудник</h3>
          </template>
          <div>
            <div class="row">
              <div class="col-md-12">
                <base-input ref="name" v-model="employee.firstName" placeholder="Имя"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input ref="name" v-model="employee.surName" placeholder="Фамилия"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input ref="name" v-model="employee.lastName" placeholder="Отчество"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input addon-left-icon="ni ni-calendar-grid-58">
                  <flat-picker slot-scope="{focus, blur}"
                               @on-open="focus"
                               @on-close="blur"
                               :config="{allowInput: true}"
                               class="form-control datepicker"
                               v-model="employee.birthDate">
                  </flat-picker>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input ref="name" v-model="employee.email" placeholder="Email"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input ref="name" v-model="employee.macAddress" placeholder="MAC-address"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input ref="name" v-model="employee.telegramId" placeholder="Telegram ID"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input ref="name" v-model="employee.position" placeholder="Должность"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-dropdown>
                  <base-button slot="title" type="secondary" size="lg" class="dropdown-toggle">
                    {{ modalDepartmentTitle }}
                  </base-button>
                  <a href="#" class="dropdown-item" :data-department-id="department.id" v-on:click="choiceItem($event)"
                     v-for="department in departments" v-bind:key="department.id">
                    {{ department.name }}
                  </a>
                </base-dropdown>
              </div>
            </div>
          </div>
          <template slot="footer">
            <base-button type="secondary" @click="modals.modal0 = false">Закрыть</base-button>
            <base-button type="primary" @click="this.employee.id === 0 ? save : update">Сохранить</base-button>
          </template>
        </modal>
      </div>
    </template>

    <div class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination :total="employeeTotal" :perPage="perPage" v-model="d"></base-pagination>
    </div>

  </div>
</template>
<script>
import axios from "axios";
import BTooltipDirective from 'bootstrap-vue/esm/directives/tooltip'
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  components: {flatPicker},
  name: 'employees-table',
  directives: {
    'b-tooltip': BTooltipDirective
  },
  props: {
    type: {
      type: String
    },
    title: String,
  },
  data() {
    return {
      tableData: [],
      tempTotal: 0,
      tempEndTime: 0,
      modals: {
        modal0: false
      },
      employee: {
        id: 0,
        firstName: '',
        surName: '',
        lastName: '',
        birthDate: '',
        email: '',
        macAddress: '',
        telegramId: '',
        department: 0,
        position: '',
      },
      employeeTotal: 3,
      perPage: 1,
      d: 1,
      departments: [],
      modalDepartmentTitle: 'Выберите отдел',
    }
  },
  async created() {
    let res = await this.getData();
    for (const item of (res.data.users)) {
      const now = new Date()
      const secondsSinceEpoch = Math.round(now.getTime() / 1000)

      let timesToday = await this.getTimesByDate(item.id, this.getCurrentTime())
      const complete = Math.round(timesToday.data.total / 28800 * 100);

      let status = '';
      let statusType = '';
      if (secondsSinceEpoch - timesToday.data.endTime > 300) {
        status = 'Не на работе';
        statusType = 'danger';
      } else {
        status = 'На работе';
        statusType = 'success';
      }

      let totalHours = Math.floor(timesToday.data.total / 3600)
      let totalMinutes = (timesToday.data.total / 60) - (totalHours * 60)

      // TODO: Сделать API для установки иконки для роутера
      let routerIcon = 'fa fa-cutlery'
      // console.log(timesToday.routers)
      if (item.id === 1) {
        routerIcon = 'fa fa-laptop'
      }

      let img = ''
      // TODO: Сделать фото для пользователей
      if (item.id === 1) {
        img = 'img/theme/team-1-800x800.jpg';
      } else if (item.id === 4) {
        img = 'img/theme/team-4-800x800.jpg';
      } else {
        img = 'img/theme/team-3-800x800.jpg';
      }

      this.tableData.push({
        id: item.id,
        img: img,
        name: item.name,
        macAddress: item.macAddress,
        timeStart: this.toDateTime(timesToday.data.beginTime),
        timeEnd: this.toDateTime(timesToday.data.endTime),
        totalHours: totalHours,
        totalMinutes: totalMinutes,
        status: status,
        statusType: statusType,
        completion: complete,
        completionColor: this.getProgressColor(complete),
        routerIcon: routerIcon,
      })
    }
  },
  mounted() {
    var app = this
    axios.get('/department')
        .then(function (resp) {
          resp.data.forEach(function (item) {
            app.departments.push({
              id: item.id,
              name: item.name,
              description: item.description,
            })
          })
        })
        .catch(function (resp) {
          console.log(resp);
        });
  },
  methods: {
    choiceItem(event) {
      event.currentTarget.parentNode.previousElementSibling.innerHTML = event.currentTarget.innerText
      this.employee.department = parseInt(event.currentTarget.dataset['departmentId'])
    },
    getData: async () => {
      return axios.get('/user')
    },
    toDateTime(seconds) {
      if (0 === seconds) {
        return '0 ч. 0 м.';
      }

      var date = new Date(seconds * 1000);

      return date.getHours() + ' ч. ' + date.getMinutes() + ' м.';
    },
    getTimesByDate: async (userId, date) => {
      return axios.get('' +
          '/time/' + userId + '/day/' + date)
          .catch(function (resp) {
            console.log(resp);
            alert("Could not load user times");
          });
    },
    save() {
      let app = this
      axios.post('/user', {
        Id: this.employee.id,
        Email: this.employee.email,
        Name: this.employee.firstName,
        Surname: this.employee.firstName,
        LastName: this.employee.firstName,
        MacAddress: this.employee.macAddress,
        TelegramId: parseInt(this.employee.telegramId),
        Department: this.employee.department,
        Position: this.employee.position,
      })
          .then(function (resp) {
            if (resp === 'User with email already register') {
              app.$notify({
                type: 'error',
                title: 'Сотрудник с данным email уже зарегистрирован'
              })
            } else {
              app.modals.modal0 = false
              app.$notify({
                type: 'success',
                title: 'Сотрудник успешно добавлен'
              })
            }
          })
          .catch(function (resp) {
            console.log(resp);
            app.modals.modal0 = false
          });
    },
    async update(employeeId) {
      let app = this
      app.modals.modal0 = true
      await this.getEmployee(employeeId)
    },
    getEmployee(employeeId) {
      let app = this
      axios.get('/user/' + employeeId)
          .then(function (resp) {
            app.employee.id = resp.data.id
            app.employee.firstName = resp.data.name
            app.employee.surName = resp.data.surname
            app.employee.lastName = resp.data.lastname
            app.employee.birthDate = resp.data.birthDate
            app.employee.email = resp.data.email
            app.employee.macAddress = resp.data.macAddress
            app.employee.telegramId = resp.data.telegramId
            app.employee.department = resp.data.department
            app.employee.position = resp.data.position

            app.departments.forEach(function (item) {
              if (item.id == app.employee.department) {
                app.modalDepartmentTitle = item.name
              }
            });
          })
          .catch(function (resp) {
            console.log(resp)
            alert("Could not load employee");
          });
    },
  }
}
</script>
<style>
</style>
