<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
                 style="min-height: 600px; background-image: url(img/theme/profile-cover.jpg); background-size: cover; background-position: center top;">
      <!-- Mask -->
      <span class="mask bg-gradient-primary opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">Привет {{ model.firstname }}</h1>
            <p class="text-white mt-0 mb-5">Это страница вашего профиля. Вы можете видеть прогресс, достигнутый в вашей
              работе, и управлять своими проектами или назначенной задачей</p>
            <a href="#!" class="btn btn-info">Редактировать профиль</a>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">

          <div class="card card-profile shadow">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <!-- TODO: Сделать фото для пользователей -->
                    <img src="img/theme/team-1-800x800.jpg" class="rounded-circle">
                  </a>
                </div>
              </div>
            </div>
            <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
              <div class="d-flex justify-content-between">
                <base-button size="sm" type="info" class="mr-4">Время</base-button>
                <base-button size="sm" type="default" class="float-right">Сообщения</base-button>
              </div>
            </div>
            <div class="card-body pt-0 pt-md-4">
              <div class="row">
                <div class="col">
                  <div class="card-profile-stats d-flex justify-content-center mt-md-5">
                    <div>
                      <span class="heading">22</span>
                      <span class="description">Friends</span>
                    </div>
                    <div>
                      <span class="heading">10</span>
                      <span class="description">Photos</span>
                    </div>
                    <div>
                      <span class="heading">89</span>
                      <span class="description">Comments</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <h3>
                  {{ model.surname }} {{ model.firstname }}<span class="font-weight-light">, {{
                    model.birthDate
                  }}</span>
                </h3>
                <!--                                <div class="h5 font-weight-300">
                                                    <i class="ni location_pin mr-2"></i>Bucharest, Romania
                                                </div>-->
                <div class="h5 mt-4">
                  <i class="ni business_briefcase-24 mr-2"></i>{{ model.position }}
                </div>
                <div>
                  <i class="ni education_hat mr-2"></i>{{ model.department }}
                </div>
                <hr class="my-4"/>
                <p>{{ model.about }}</p>
                <!--                                <a href="#">Show more</a>-->
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-8 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Информация о сотруднике</h3>
                </div>
                <div class="col-4 text-right">
                  <router-link to="/user-settings" class="btn btn-sm btn-primary">Настройки</router-link>

                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">Общая информация</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-4">
                      <base-input alternative=""
                                  label="Фамилия"
                                  placeholder="Фамилия"
                                  input-classes="form-control-alternative"
                                  v-model="model.surname"
                      />
                    </div>
                    <div class="col-lg-4">
                      <base-input alternative=""
                                  label="Имя"
                                  placeholder="Имя"
                                  input-classes="form-control-alternative"
                                  v-model="model.firstname"
                      />
                    </div>
                    <div class="col-lg-4">
                      <base-input alternative=""
                                  label="Отчество"
                                  placeholder="Отчество"
                                  input-classes="form-control-alternative"
                                  v-model="model.lastname"
                      />
                    </div>
                  </div>
                </div>
                <hr class="my-4"/>
                <!-- Address -->
                <h6 class="heading-small text-muted mb-4">Контактная информация</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-md-12">
                      <base-input alternative=""
                                  label="Домашний адрес"
                                  placeholder="Домашний адрес"
                                  input-classes="form-control-alternative"
                                  v-model="model.address"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <base-input alternative=""
                                  label="Контактный номер телефона"
                                  placeholder="Контактный номер телефона"
                                  input-classes="form-control-alternative"
                                  v-model="model.phone"
                      />
                    </div>
                  </div>
                </div>
                <hr class="my-4"/>
                <!-- Description -->
                <h6 class="heading-small text-muted mb-4">Обо мне</h6>
                <div class="pl-lg-4">
                  <div class="form-group">
                    <base-input alternative="" label="Обо мне">
                      <textarea rows="4" class="form-control form-control-alternative"
                                placeholder="Несколько слов о Вас ..."></textarea>
                    </base-input>
                  </div>
                </div>
                <hr class="my-4"/>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-md-12">
                      <base-button type="primary">Сохранить</base-button>
                    </div>
                  </div>
                </div>
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'user-profile',
  data() {
    return {
      model: {
        surname: this.$store.state.user.surname,
        email: this.$store.state.user.email,
        firstname: this.$store.state.user.firstname,
        lastname: this.$store.state.user.lastname,
        address: '',
        city: '',
        country: '',
        zipCode: '',
        about: this.$store.state.user.about,
        birthDate: this.getAge(this.$store.state.user.birthDate),
        position: this.$store.state.user.position,
        department: this.$store.state.user.department,
      }
    }
  },
  mounted() {
  },
  methods: {
    getAge(birthDateString) {
      var today = new Date();
      var birthDate = new Date(birthDateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    }
  }
};
</script>
<style></style>
