<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">По отделам</h3>
        </div>
        <div class="col text-right">
          <base-button size="sm" type="primary">Посмотреть полностью</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table thead-classes="thead-light"
                  :data="tableData">
        <template slot="columns">
          <th>Название</th>
          <th>Количество сотрудников</th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            {{row.name}}
          </th>
          <td>
            {{row.numEmployees}}
          </td>
          <td>
            <div class="d-flex align-items-center">
              <span class="mr-2">{{row.progress}}%</span>
              <base-progress :type="row.progressType"
                             class="pt-0"
                             :show-percentage="false"
                             :value="row.progress"
              />
            </div>
          </td>
        </template>

      </base-table>
    </div>

  </div>
</template>
<script>
  import axios from "axios";

  export default {
    name: 'social-traffic-table',
    data() {
      return {
        tableData: [
          /*{
            name: 'Facebook',
            visitors: '1,480',
            progress: 60,
            progressType: 'gradient-danger',
          },
          {
            name: 'LinkedIn',
            visitors: '5,480',
            progress: 70,
            progressType: 'gradient-primary',
          },
          {
            name: 'Google',
            visitors: '4,807',
            progress: 80,
            progressType: 'gradient-primary',
          },
          {
            name: 'Instagram',
            visitors: '3,678',
            progress: 75,
            progressType: 'gradient-info',
          },
          {
            name: 'Twitter',
            visitors: '2,645',
            progress: 30,
            progressType: 'gradient-warning',
          }*/
        ]
      }
    },
    mounted() {
      let app = this;

      axios.get('/stat/departments/' + this.getCurrentTime())
          .then(function (resp) {
            resp.data.departments.forEach(function (item) {
              let progress = app.calcPercentByDepartment(item)
              app.tableData.push({
                id: item.departmentId,
                name: item.departmentName,
                numEmployees: item.numEmployees,
                total: item.total,
                progress: progress,
                progressType: 'gradient-' + app.getProgressColor(progress),
              })
            })
          })
          .catch(function (resp) {
            console.log(resp);
            alert("Could not load departments");
          });
    },
    methods: {
      calcPercentByDepartment(statDepartment) {
        if (0 === statDepartment.totalDay * statDepartment.numEmployees) {
          return 0
        }

        return Math.round(statDepartment.total / (statDepartment.totalDay * statDepartment.numEmployees) * 100)
      }
    }
  }
</script>
<style>
</style>
