<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <base-button type="primary" size="sm" @click="modals.modal0 = true">Добавить</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="tableData">
        <template slot="columns">
          <th>Название</th>
          <th>Дата начала</th>
          <th>Дата окончания</th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <td>{{ row.name }}</td>
          <td>{{ row.beginDate }}</td>
          <td>{{ row.endDate }}</td>

          <td class="text-right">
            <base-dropdown class="dropdown"
                           position="right">
              <a slot="title" class="btn btn-sm btn-icon-only text-light" role="button" data-toggle="dropdown"
                 aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <a class="dropdown-item" href="#" @click="updatePeriod(row.id)" :data-id="row.id">Редактировать</a>
                <a class="dropdown-item" href="#">Удалить</a>
              </template>
            </base-dropdown>
          </td>
        </template>

      </base-table>
    </div>
    <template>
      <div>
        <modal :show.sync="modals.modal0">
          <template slot="header">
            <h3 class="modal-title" id="periodFormModalLabel">Новый период</h3>
          </template>
          <div>
            <div class="row">
              <div class="col-md-12">
                <base-input ref="name" v-model="period.name" placeholder="Июль"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input ref="date_range_input" addon-left-icon="ni ni-calendar-grid-58">
                  <flat-picker slot-scope="{focus, blur}"
                               @on-open="focus"
                               @on-close="blur"
                               :config="{allowInput: true, mode: 'range'}"
                               class="form-control datepicker"
                               ref="date_range"
                               v-model="period.dateRange">
                  </flat-picker>
                </base-input>
              </div>
            </div>
          </div>
          <template slot="footer">
            <base-button type="secondary" @click="modals.modal0 = false">Закрыть</base-button>
            <base-button type="primary" @click="this.period.id === 0 ? store : save">Сохранить</base-button>
          </template>
        </modal>

      </div>
    </template>

    <!--    <div class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
          <base-pagination total="30"></base-pagination>
        </div>-->

  </div>
</template>
<script>
import axios from "axios";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: 'periods-table',
  components: {
    flatPicker
  },
  props: {
    type: {
      type: String
    },
    title: String,
  },
  data() {
    return {
      tableData: [],
      modals: {
        modal0: false
      },
      period: {
        id: 0,
        name: '',
        dateRange: ''
      },
    }
  },
  mounted() {
    let app = this;

    axios.get('/period')
        .then(function (resp) {
          resp.data.periods.forEach(function (item) {
            app.tableData.push({
              id: item.id,
              name: item.name,
              beginDate: item.beginDate,
              endDate: item.endDate,
            })
          })
        })
        .catch(function (resp) {
          console.log(resp);
          alert("Could not load users");
        });
  },
  methods: {
    updatePeriod(id) {
      let app = this;
      this.period.id = id
      document.getElementById('periodFormModalLabel').innerHTML = 'Изменение периода';

      axios.get('/period/' + this.period.id)
          .then(function (resp) {
            app.period.name = resp.data.name
            app.period.dateRange = resp.data.beginDate + ' to ' + resp.data.endDate
          })
          .catch(function (resp) {
            console.log(resp);
            alert("Could not save new period1");
          });

      this.modals.modal0 = true
    },
    store() {
      let app = this
      axios.patch('/period/' + this.period.id, {Id: this.period.id, Name: this.period.name, Year: 2020, BeginDate: this.period.dateRange.substr(0, 10), EndDate: this.period.dateRange.substr(14, 10)})
        .then(function (resp) {
          app.modals.modal0 = false
          console.log(resp);
        })
        .catch(function (resp) {
          console.log(resp);
          alert("Could not save new period");
        });
    },
    save() {
      let app = this
      this.period.name = this.$refs['name'].value;

      var valid = false;
      if (!this.period.name) {
        this.$refs['name'].valid = false
      } else {
        valid = true
        this.$refs['name'].valid = true
      }

      if (!this.period.dateRange) {
        this.$refs['date_range_input'].valid = false
      } else {
        valid = valid && true
        this.$refs['date_range_input'].valid = true
      }

      if (!valid) {
        return true
      }

      axios.post('/period', {Id: 0, Name: this.period.name, Year: 2020, BeginDate: this.period.dateRange.substr(0, 10), EndDate: this.period.dateRange.substr(14, 10)})
          .then(function (resp) {
            app.modals.modal0 = false
            console.log(resp);
          })
          .catch(function (resp) {
            console.log(resp);
            alert("Could not save new period");
          });
    },
  }
}
</script>
<style>
</style>
