<template>
    <div>
        <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
          <div class="row">
            <div class="col">
              <routers-table title="Роутеры"></routers-table>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
import RoutersTable from './Tables/RoutersTable'
export default {
  name: 'tables',
  components: {
    RoutersTable
  }
};
</script>
<style></style>
