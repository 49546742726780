<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <base-button type="primary" size="sm" @click="modals.modal0 = true">Добавить</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="tableData">
        <template slot="columns">
          <th>Название</th>
          <th>Описание</th>
          <th>Адрес</th>
          <th>Логин</th>
          <th>Пароль</th>
          <th>Статус</th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <td>{{ row.name }}</td>
          <td>{{ row.description }}</td>
          <td>{{ row.address }}</td>
          <td>{{ row.login }}</td>
          <td><span class="password" @click="showHidePassword(row, $event)">{{ row.passwordVisible ? row.password : passwordMask }}</span></td>
          <td>
            <i class="ni ni-laptop" v-bind:class="[row.status ? 'text-green' : 'text-red']"></i>
<!--            <base-switch v-model="switches.off"></base-switch>-->
          </td>

          <td class="text-right">
            <base-dropdown class="dropdown" position="right">
              <a slot="title" class="btn btn-sm btn-icon-only text-light" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <a class="dropdown-item" href="#" @click="updateRouter" :data-id="row.id">Редактировать</a>
                <a class="dropdown-item" href="#">Удалить</a>
              </template>
            </base-dropdown>
          </td>
        </template>

      </base-table>
    </div>
    <template>
      <div>
        <modal :show.sync="modals.modal0">
          <template slot="header">
            <h3 class="modal-title" id="periodFormModalLabel">Новый роутер</h3>
          </template>
          <div>
            <div class="row">
              <div class="col-md-12">
                <base-input ref="name" v-model="router.name" placeholder="Название"></base-input>
              </div>
            </div>
          </div>
          <template slot="footer">
            <base-button type="secondary" @click="modals.modal0 = false">Закрыть</base-button>
            <base-button type="primary" @click="this.router.id === 0 ? save : update">Сохранить</base-button>
          </template>
        </modal>

      </div>
    </template>
  </div>
</template>
<script>

import axios from "axios";

export default {
  name: 'routers-table',
  props: {
    type: {
      type: String
    },
    title: String,
  },
  data() {
    return {
      tableData: [],
      modals: {
        modal0: false
      },
      switches: {
        off: false,
        on: true
      },
      passwordMask: '*****',
      router: {
        id: 0,
        name: '',
        description: '',
        address: '',
        login: '',
        password: '',
        passwordVisible: false,
        status: false,
      }
    }
  },
  mounted() {
    let app = this;

    axios.get('/router')
        .then(function (resp) {
          resp.data.content.forEach(function (item) {
            app.tableData.push({
              id: item.id,
              name: item.name,
              description: item.description,
              address: item.address,
              login: item.login,
              password: item.password,
              passwordVisible: false,
              status: item.status
            })
          })
        })
        .catch(function (resp) {
          console.log(resp);
          alert("Could not load routers");
        });
  },
  methods: {
    showHidePassword(router, event) {
      router.passwordVisible = !router.passwordVisible
      event.target.innerText = router.passwordVisible ? router.password : this.passwordMask
    },
    updateRouter() {
      let app = this
      app.modals.modal0 = true
    },
    save() {

    },
    update() {

    },
  },
}
</script>
<style>
.password {
  cursor: pointer;
}
</style>
