<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <periods-table title="Рабочие периоды"></periods-table>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import PeriodsTable from './Tables/PeriodsTable'

export default {
  name: 'tables',
  components: {
    PeriodsTable
  },

};
</script>
<style></style>
