<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <base-button type="primary" size="sm" @click="modals.modal0 = true">Добавить</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="tableData">
        <template slot="columns">
          <th>Дата</th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <td>{{ row.date }}</td>

          <td class="text-right">
            <base-dropdown class="dropdown"
                           position="right">
              <a slot="title" class="btn btn-sm btn-icon-only text-light" role="button" data-toggle="dropdown"
                 aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <a class="dropdown-item" href="#" @click="updateDate(row.id)" :data-id="row.id">Редактировать</a>
                <a class="dropdown-item" href="#">Удалить</a>
              </template>
            </base-dropdown>
          </td>
        </template>

      </base-table>
    </div>
    <template>
      <div>
        <modal :show.sync="modals.modal0">
          <template slot="header">
            <h3 class="modal-title" id="periodFormModalLabel">Нерабочий день</h3>
          </template>
          <div>
            <div class="row">
              <div class="col-md-12">
                <base-input addon-left-icon="ni ni-calendar-grid-58">
                  <flat-picker slot-scope="{focus, blur}"
                               @on-open="focus"
                               @on-close="blur"
                               :config="{allowInput: true}"
                               class="form-control datepicker">
                  </flat-picker>
                </base-input>
              </div>
            </div>
          </div>
          <template slot="footer">
            <base-button type="secondary" @click="modals.modal0 = false">Закрыть</base-button>
            <base-button type="primary" @click="this.period.id === 0 ? store : save">Сохранить</base-button>
          </template>
        </modal>

      </div>
    </template>

    <!--    <div class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
          <base-pagination total="30"></base-pagination>
        </div>-->

  </div>
</template>
<script>
import axios from "axios";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: 'production-calendar-table',
  components: {
    flatPicker
  },
  props: {
    type: {
      type: String
    },
    title: String,
  },
  data() {
    return {
      tableData: [],
      modals: {
        modal0: false
      },
      period: {
        id: 0,
        name: '',
        dateRange: ''
      },
    }
  },
  mounted() {
    let app = this;

    axios.get('/weekend')
        .then(function (resp) {
          resp.data.forEach(function (item) {
            app.tableData.push({
              date: item,
            })
          })
        })
        .catch(function (resp) {
          console.log(resp);
          alert("Could not load production calendar");
        });
  },
  methods: {
    store() {
      // TODO
    },
    save() {
      // TODO
    },
    updateDate() {
      // TODO
    }
  }
}
</script>
<style>
</style>
